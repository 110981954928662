import * as React from "react";

import Layout from "../../components/layout";
import SEO from "../../components/seo/seo";
import { LogoBunny } from "../../components/site/header/header";
import Navigation from "../../components/site/navigation/navigation";
import { TOP_NAV_ITEM } from "../../constants/top-navigations";
import { Topic } from "../../types/topics";
import socialImage from "../../images/ask-about-social-covid.jpg";
import COVID19Home from "../../components/covid-19/covid-19-home/covid-19-home";

const Covid19Page: React.FC = () => (
  <Layout
    topic={Topic.COVID19}
    description="coronavirus"
    headerUrl="/covid-19/"
    logoBunny={LogoBunny.FaceMask}
    isTopicHomePage={true}
  >
    <SEO
      title="COVID-19"
      description="Get quick answers around 2019 novel coronavirus."
      imageUrl={socialImage}
    />

    <Navigation topic={Topic.COVID19} activeItem={TOP_NAV_ITEM.CovidHome} />

    <COVID19Home />
  </Layout>
);

export default Covid19Page;
